import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)


export const constantRouterMap = [
  {
    path: '/',
    name: 'EntryIndex',
    component: () => import('@/views/EntryIndex'),
    children: [
      {
        path: '/',
        name: '首页',
        component: () => import('@/views/HomePage'),
      },
      {
        path: '/ProductServices',
        name: '产品服务',
        component: () => import('@/views/ProductServices/index.vue'),
        children: [
          {
            path: '/ProductServices',
            name: '教学',
            component: () => import('@/views/ProductServices/teaching.vue'),
          },
          {
            path: '/ProductServices/educAffair',
            name: '教务',
            component: () => import('@/views/ProductServices/educAffair.vue'),
          },
          {
            path: '/ProductServices/ideology',
            name: '思政',
            component: () => import('@/views/ProductServices/ideology.vue'),
          },
          {
            path: '/ProductServices/nonAcademic',
            name: '非学历',
            component: () => import('@/views/ProductServices/nonAcademic.vue'),
          },
          {
            path: '/ProductServices/bigDataCenter',
            name: '校本大数据中心',
            component: () => import('@/views/ProductServices/bigDataCenter.vue'),
          },
          {
            path: '/ProductServices/smartBrain',
            name: '智慧大脑',
            component: () => import('@/views/ProductServices/smartBrain.vue'),
          },
          {
            path: '/ProductServices/teachingTraining',
            name: '教师混合教学能力提升',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/accomplishmentTraining',
            name: '教师数字素养能力提升',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/ideologyTraining',
            name: '思政教学能力提升',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/leadershipTraining',
            name: '信息化领导力提升',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/vocationalEduTraining',
            name: '职业教育信息化标杆学校建设与申报',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/premiumCoursesTraining',
            name: '职教在线精品课程建设与申报',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/vocationalCollegesTraining',
            name: '职业院校技能大赛教学能力比赛比赛指导',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/undergraduateCoursesTraining',
            name: '一流本科课程建设与申报',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
          {
            path: '/ProductServices/teachResearchTraining',
            name: '教学研究方法与教研论文撰写',
            component: () => import('@/views/trainingProducts/index.vue'),
          },
        ]
      },
      {
        path: '/NewsInfo',
        name: '新闻专题',
        component: () => import('@/views/NewsInfo/index.vue'),
      },
      {
        path: '/AboutUs',
        name: '关于我们',
        component: () => import('@/views/AboutUs/index.vue'),
      },
      {
        path: '/recruit',
        name: '招贤纳士',
        component: () => import('@/views/recruit/index.vue'),
      },
      {
        path: '/contactUs',
        name: '联系我们',
        component: () => import('@/views/contactUs.vue'),
      },
    ]
  },
]

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})


router.beforeEach((to, from, next) => {
  next()
})
export default router
