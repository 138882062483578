<template>
  <div id="app">
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import devPixelRatio from "@/utils/devicePixelRatio.js";
export default {
  name: 'App',
  created() {
    new devPixelRatio().init(); // 初始化页面比例
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* 显示两行，可以根据需要调整行数 */
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
